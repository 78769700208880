import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import HcpdProviderListItem from './HcpdProviderListItem'
import { RegionContainer, NoResultsMessage } from '../styles/Utility.styles'
import { haversineDistance } from '../../util/geolocation'
import PaginationControl from '../PaginatonControl'

const ITEMS_PER_PAGE = 25

const HCPD_PROVIDER_LIST_QUERY = graphql`
  {
    allHealthCareProvider(filter: { approval_status: { eq: "APPROVED" } }) {
      nodes {
        id
        slug
        address_line_1
        address_line_2
        city
        clinic_name
        country
        designation
        first_name
        middle_name
        last_name
        is_in_mpm
        is_in_presidents_council
        is_in_corrona_registry
        payment_methods
        phone
        specialties
        state
        treatments
        url
        zip
        clinical_trials
        sex
        languages_spoken
        telehealth
        latitude
        longitude
      }
    }
  }
`

const getSortedHcps = hcps => {
  const presCouncil = hcps
    .filter(hcp => hcp.is_in_presidents_council)
    .sort((a, b) => (a.last_name < b.last_name ? -1 : 1))
  const notPresCouncil = hcps.filter(hcp => !hcp.is_in_presidents_council)
  const proMembers = notPresCouncil
    .filter(hcp => hcp.is_in_mpm)
    .sort((a, b) => (a.last_name < b.last_name ? -1 : 1))
  const notProMembers = notPresCouncil.filter(hcp => !hcp.is_in_mpm)
  const corronas = notProMembers
    .filter(hcp => hcp.is_in_corrona_registry)
    .sort((a, b) => (a.last_name < b.last_name ? -1 : 1))
  const remainder = notProMembers
    .filter(hcp => !hcp.is_in_corrona_registry)
    .sort((a, b) => (a.last_name < b.last_name ? -1 : 1))
  return [...presCouncil, ...proMembers, ...corronas, ...remainder]
}

const HcpdProviderList = ({
  latLon,
  radius,
  additionalFilters,
  searchFilter,
  noResultsMessage,
  paginationChangeAction,
}) => {
  const [firstItemForPage, setFirstItemForPage] = useState(0)
  const [lastItemForPage, setLastItemForPage] = useState(ITEMS_PER_PAGE)

  return (
    <StaticQuery
      query={`${HCPD_PROVIDER_LIST_QUERY}`}
      render={data => {
        const hcps = getSortedHcps(data.allHealthCareProvider.nodes)
          .filter(hcp =>
            latLon && latLon.lat
              ? haversineDistance(
                  [latLon.lat, latLon.lng],
                  [hcp.latitude, hcp.longitude]
                ) <= radius
              : 1
          )
          .filter(hcp => {
            const fullName = `${hcp.first_name.toUpperCase()} ${hcp.last_name.toUpperCase()}`
            return (
              hcp.first_name
                .toUpperCase()
                .startsWith(searchFilter.toUpperCase()) ||
              hcp.last_name
                .toUpperCase()
                .startsWith(searchFilter.toUpperCase()) ||
              fullName.startsWith(searchFilter.toUpperCase()) ||
              hcp.clinic_name
                .toUpperCase()
                .startsWith(searchFilter.toUpperCase())
            )
          })
          .filter(hcp =>
            additionalFilters.every(filter =>
              hcp.specialties
                .concat(
                  hcp.treatments,
                  hcp.clinical_trials,
                  hcp.payment_methods,
                  hcp.languages_spoken,
                  [hcp.sex],
                  hcp.telehealth && ['Telehealth']
                )
                .includes(filter)
            )
          )
        return (
          <RegionContainer>
            {hcps.length <= 0 ? (
              <NoResultsMessage>{noResultsMessage}</NoResultsMessage>
            ) : (
              <>
                {hcps.slice(firstItemForPage, lastItemForPage).map(node => (
                  <HcpdProviderListItem key={node.id} node={node} />
                ))}
                <PaginationControl
                  itemCount={hcps.length}
                  itemsPerPage={ITEMS_PER_PAGE}
                  setFirstItemForPage={setFirstItemForPage}
                  setLastItemForPage={setLastItemForPage}
                  paginationChangeAction={paginationChangeAction}
                />
              </>
            )}
          </RegionContainer>
        )
      }}
    />
  )
}

export default HcpdProviderList
